'use client';

import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { useModal } from '@/hooks/use-modal-store';
import { useRouter } from 'next/navigation';
import axios from 'axios';
import { Button } from '../ui/button';
import { useState } from 'react';
import { getUserSubscriptionPlan } from '@/lib/subscription';
import { getUsage } from '@/lib/api-limit';

export const ManageProModal = () => {
  const plan = 'FREE';
  const usage = { creditsUsed: 0, creditsTotal: 100 };

  const { isOpen, onClose, type } = useModal();
  const [loading, setLoading] = useState(false);
  const [creditToAdd, setCreditToAdd] = useState(10); // Initial value for credits
  const router = useRouter();

  const isModalOpen = isOpen && type === 'managePro';

  const handlePlanChange = async (plan: string) => {
    try {
      setLoading(true);
      const response = await axios.post('/api/stripe', { plan });
      window.location.href = response.data.url;
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleAddCredit = async () => {
    try {
      setLoading(true);
      const response = await axios.post('/api/stripe', { credits: creditToAdd });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      open={isModalOpen}
      onOpenChange={handleClose}
    >
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Gestion des abonnements</DialogTitle>
        </DialogHeader>
        <div>
          <p>
            Votre plan : <strong>{plan}</strong>
          </p>
          <Button
            onClick={() => handlePlanChange('pro')}
            className="btn-primary mr-2"
            disabled={loading}
          >
            PRO (29,90€/mois)
          </Button>
          <Button
            onClick={() => handlePlanChange('premium')}
            className="btn-primary"
            disabled={loading}
          >
            PREMIUM (49,90€/mois)
          </Button>
          <p className="text-xs text-muted-foreground">
            Modifiez votre plan d'abonnement selon les besoins de votre entreprise.
          </p>
        </div>

        <div className="flex items-center justify-between my-4">
          <hr className="w-full" />
          <span className="p-2 text-sm text-gray-400">OU</span>
          <hr className="w-full" />
        </div>

        <div className="mb-4">
          <p>
            Vos crédits :{' '}
            <strong>
              {usage?.creditsUsed} / {usage?.creditsTotal}
            </strong>
          </p>
          <div className="flex items-center">
            <input
              type="range"
              min="1"
              max="100"
              value={creditToAdd}
              className="w-full mr-4"
              onChange={(e) => setCreditToAdd(Number(e.target.value))}
            />
            <Button
              onClick={handleAddCredit}
              className="btn-secondary"
              disabled={loading}
            >
              Ajouter {creditToAdd} Crédit(s)
            </Button>
          </div>
          <p className="text-xs text-muted-foreground mt-2">
            Ajoutez du crédit à votre compte pour une utilisation future ou pour couvrir les frais d'abonnement en
            cours.
          </p>
        </div>
      </DialogContent>
    </Dialog>
  );
};
