'use client';

import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { useModal } from '@/hooks/use-modal-store';
import { InlineWidget } from 'react-calendly';
import { cn } from '@/lib/utils';

export const CalendlyModal = () => {
  const { isOpen, onClose, type } = useModal();

  const isModalOpen = isOpen && type === 'calendly';

  return (
    <Dialog
      open={isModalOpen}
      onOpenChange={onClose}
    >
      <DialogContent
        className={cn(
          'p-0 overflow-hidden',
          type === 'calendly' ? 'sm:max-w-[900px] w-[90vw] h-[80vh]' : 'sm:max-w-[600px]'
        )}
      >
        <DialogHeader className="p-6 pb-0">
          <DialogTitle>Planifier un rendez-vous</DialogTitle>
        </DialogHeader>
        <div className="calendly-inline-widget h-full">
          <InlineWidget
            url="https://calendly.com/contact-aiqido/30min"
            styles={{
              height: 'calc(80vh - 80px)',
              width: '100%',
            }}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};
