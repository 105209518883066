'use client';

import { useEffect, useState } from 'react';
import { ManageProModal } from '../modals/manage-pro';
import { CreateAssistantModal } from '../modals/create-assistant';
import { CalendlyModal } from '../modals/calendly';

export const ModalProvider = () => {
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    setIsMounted(true);
  }, []);

  if (!isMounted) return null;

  return (
    <>
      <ManageProModal />
      <CreateAssistantModal />
      <CalendlyModal />
    </>
  );
};
