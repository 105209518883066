'use client';

import { toast } from 'sonner';
import { z } from 'zod';
import axios from 'axios';
import { zodResolver } from '@hookform/resolvers/zod';

import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { useModal } from '@/hooks/use-modal-store';
import { useForm } from 'react-hook-form';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Slider } from '@/components/ui/slider';
import { useRouter } from 'next/navigation';

const AssistantFormSchema = z.object({
  name: z.string().min(1, 'Assistant name is required.'),
  model: z.enum(['GPT_4_O', 'GPT_3_5_TURBO', 'GPT_4']),
  provider: z.enum(['OPENAI']),
  temperature: z.number().min(0).max(2),
});
type AssistantFormValues = z.infer<typeof AssistantFormSchema>;

export const CreateAssistantModal = () => {
  const { isOpen, onClose, type } = useModal();
  const router = useRouter();

  const isModalOpen = isOpen && type === 'createAssistant';

  const form = useForm<AssistantFormValues>({
    mode: 'onChange',
    resolver: zodResolver(AssistantFormSchema),
    defaultValues: {
      name: '',
      model: 'GPT_4_O',
      provider: 'OPENAI',
      temperature: 1,
    },
  });

  const isLoading = form.formState.isSubmitting;

  const onSubmit = async (data: AssistantFormValues) => {
    try {
      const response = await axios.post('/api/assistants', data);

      toast.success('Assistant created successfully!');

      localStorage.setItem('selectedAssistant', response.data.id);

      form.reset();
      router.refresh();
      onClose();
    } catch (error) {
      toast.error('Error creating assistant. Please try again.');
    } finally {
      window.location.reload(); // To improve
    }
  };

  const handleClose = () => {
    form.reset();
    onClose();
  };

  return (
    <Dialog
      open={isModalOpen}
      onOpenChange={handleClose}
    >
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Create an Assistant</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="mb-4">
              <FormField
                name="name"
                control={form.control}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Assistant Name</FormLabel>
                    <Input
                      {...field}
                      placeholder="Assistant name"
                      disabled={isLoading}
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="mb-4">
              <FormField
                name="provider"
                control={form.control}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Provider</FormLabel>
                    <Select
                      value={field.value}
                      onValueChange={field.onChange}
                      disabled={isLoading}
                    >
                      <SelectTrigger className="w-[300px]">
                        <SelectValue placeholder="Select a provider" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          <SelectItem value="OPENAI">OpenAI</SelectItem>
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="mb-4">
              <FormField
                name="model"
                control={form.control}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Model</FormLabel>
                    <Select
                      value={field.value}
                      onValueChange={field.onChange}
                      disabled={isLoading}
                    >
                      <SelectTrigger className="w-[300px]">
                        <SelectValue placeholder="Select a model" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          <SelectItem value="GPT_4_O">GPT-4-O</SelectItem>
                          <SelectItem value="GPT_3_5_TURBO">GPT-3.5 Turbo</SelectItem>
                          <SelectItem value="GPT_4">GPT-4</SelectItem>
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="mb-4">
              <FormField
                name="temperature"
                control={form.control}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Temperature ({field.value})</FormLabel>
                    <FormControl>
                      <Slider
                        value={[field.value]}
                        onValueChange={(value) => field.onChange(value[0])}
                        min={0}
                        max={2}
                        step={0.1}
                        disabled={isLoading}
                      />
                    </FormControl>
                    <p className="text-sm text-muted-foreground mt-2">
                      Higher values like 0.8 will make the output more random, while lower values like 0.2 will make it
                      more focused and deterministic.
                    </p>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <DialogFooter className="p-6">
              <Button
                type="submit"
                disabled={isLoading || !form.formState.isValid}
              >
                Submit
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
