'use client';

import { PropsWithChildren, useEffect } from 'react';
import { Crisp } from 'crisp-sdk-web';

import { ThemeProvider } from '@/components/providers/theme-provider';
import { Toaster } from '@/components/ui/sonner';
import { ModalProvider } from './providers/modal-provider';
import { CrispProvider } from './providers/crisp-provider';

export const CrispChat = () => {
  useEffect(() => {
    if (process.env.NEXT_PUBLIC_CRISP_WEBSITE_ID) Crisp.configure(process.env.NEXT_PUBLIC_CRISP_WEBSITE_ID!);
  }, []);

  return null;
};

export type ProvidersProps = PropsWithChildren;

export const Providers = (props: ProvidersProps) => {
  return (
    <>
      <ThemeProvider
        attribute="class"
        defaultTheme="system"
        enableSystem
        disableTransitionOnChange
      >
        <CrispProvider />
        <ModalProvider />
        <Toaster />
        {props.children}
        <CrispChat />
      </ThemeProvider>
    </>
  );
};
